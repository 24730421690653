import * as React from "react"

import { TypeTag } from "../../app/createPages"

type ArticleTagsProps = {
  tags: TypeTag[]
}

const ArticleTags = ({ tags }: ArticleTagsProps) => {
  return (
    <React.Fragment>
      {tags.map(tag => (
        <div
          className="category p-1 bg-gray-light-7 text-black mr-1 mb-1"
          key={tag.title}
        >
          <p className="overline m-0">{tag.title}</p>
        </div>
      ))}
    </React.Fragment>
  )
}

export default ArticleTags
