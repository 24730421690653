import * as React from "react"

import { TagManagerContext } from "../../core/contexts/tagManager"
import Card from "../card/card"
import ArticleTags from "./articleTags"
import ArticleImage from "./articleImage"

type Size = "normal" | "dense"

type ArticlePreviewCardProps = {
  article: any
  size?: Size
  asInsight?: boolean
}

const isSizeDense = (size: Size) => size == "dense"

const ArticlePreviewCard = ({
  article,
  size = "normal",
  asInsight = false,
}: ArticlePreviewCardProps) => {
  const tagManager = React.useContext(TagManagerContext)
  const isDense = isSizeDense(size)

  const options: Intl.DateTimeFormatOptions = {
    dateStyle: "long",
  }

  const image = article.mainImage ? (
    <ArticleImage image={article.mainImage} alt={article.mainImageAltText} />
  ) : null

  const datetime = (
    <p className="overline">
      {new Intl.DateTimeFormat(["en-ZA"], options).format(
        new Date(article.publishedAt)
      )}
    </p>
  )

  const title = <h6 className="m-0 subtitle1">{article.title}</h6>

  const description = <p className="mt-2 mb-2">{article.excerpt}</p>

  const tags = (
    <div className="row">
      <ArticleTags tags={article.tags ?? []} />
    </div>
  )

  return (
    <div
      onClick={() =>
        tagManager?.onContentSelectEvent({
          content_type: "article",
          item_id: article.id,
        })
      }
    >
      <Card
        canHover={true}
        href={`/resources/${asInsight ? "insights" : "blog"}/${
          article.category.slug.current
        }/${article.slug.current}`}
      >
        <div className="row mb-1">
          {image && (
            <div
              className={`display-i-f ${
                isDense ? "col-xs-3" : "col-xs-12 mb-2"
              }`}
            >
              {image}
            </div>
          )}
          <div
            className={
              image ? (isDense ? "col-xs-9 pl-2" : "pl-0") : "col-xs-12 pl-0"
            }
          >
            {datetime}
            {title}
            {description}
            {tags}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ArticlePreviewCard
