import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"

const getImage = (image: any, aspectRatio: number) => {
  const imageData = image.asset?.gatsbyImageData
  if (imageData) {
    return imageData
  } else {
    return getGatsbyImageData(
      image.asset,
      { aspectRatio },
      {
        projectId: process.env.GATSBY_SANITY_PROJECT_ID ?? "",
        dataset: process.env.GATSBY_SANITY_DATASET ?? "",
      }
    )
  }
}

type ArticleImageProps = {
  image: any
  alt: string
  aspectRatio?: number
}

const ArticleImage = ({
  image,
  alt,
  aspectRatio = 16 / 9,
}: ArticleImageProps) => {
  return <GatsbyImage image={getImage(image, aspectRatio)} alt={alt} />
}

export default ArticleImage
